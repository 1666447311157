import React from "react";
import { NextPage } from "../@types/next-overrides";

import ErrorComponent from "../components/error";

export { getStaticProps } from "@/util/auth/get-props";

const Error404Page: NextPage = () => {
  return (
    <ErrorComponent
      title="Whoops!"
      description="Looks like that doesn't exist"
    />
  );
};

export default Error404Page;
